/* Modal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 809800 !important;
}

.modal-content {
    background-color: #fff;
    border-radius: 8px;
    /* max-width: 800px; */
    width: 100% !important;
    display: flex;
    flex-direction: column;
    position: relative;
}


.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}
.back-button {
    position: absolute;
    top: 10px;
    left: 10px;
}


/* Buttons */

.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    border: 1px solid #ddd;
}

.login-button .icon {
    margin-right: 10px;
}

.or-divider {
    display: flex;
    align-items: center;
    width: 100%;
}

.or-divider span {
    margin: 0 3px;
}

.or-divider:before,
.or-divider:after {
    content: '';
    flex: 0.4;
    border-bottom: 0.8px solid #e5e5e5;
    margin: 0 10px;
}


@media (max-width:867px) {
    .left-side {
        display: none !important;
    }

    .right-side {
        padding: 20px;
        width: 100% !important;
    }
}



/* Modal.css */
.dropdown-menu-custom {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

.popup-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 250px;
    z-index: 6000 !important;
    display: block;
}

.dropdown-item-text {
    padding: 0.5rem 1rem;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-divider {
    margin: 0;
}