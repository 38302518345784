@font-face {
    font-family: 'Sansation_Bold';
    src: url('./../../public/fonts/Sansation_Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Sansation_Regular';
    src: url('./../../public/fonts/Sansation_Regular.ttf') format('truetype');
}

/* SourceSan */

@font-face {
    font-family: 'Source_Regular';
    src: url('./../../public/fonts/SourceSans3-Regular.ttf');
}

@font-face {
    font-family: 'Source_Medium';
    src: url('./../../public/fonts/SourceSans3-Medium.ttf');
}

@font-face {
    font-family: 'Source_SemiBold';
    src: url('./../../public/fonts/SourceSans3-SemiBold.ttf');
}

@font-face {
    font-family: 'Source_Bold';
    src: url('./../../public/fonts/SourceSans3-Bold.ttf');
}


/* Rubik */
@font-face {
    font-family: 'Rubik-Light';
    src: url('./../../public/fonts/Rubik-Light.ttf');
}

@font-face {
    font-family: 'Rubik-Regular';
    src: url('./../../public/fonts/Rubik-Regular.ttf');
}

@font-face {
    font-family: 'Rubik-Medium';
    src: url('./../../public/fonts/Rubik-Medium.ttf');
}

@font-face {
    font-family: 'Rubik-Bold';
    src: url('/public/fonts/Rubik-Bold.ttf');
}

@font-face {
    font-family: 'Rubik-SemiBold';
    src: url('/public/fonts/Rubik-SemiBold.ttf');
}

/* Nunito Sans */

@font-face {
    font-family: 'NunitoSans_10pt-Light';
    src: url('./../../public/fonts/NunitoSans_10pt-Light.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-Regular';
    src: url('./../../public/fonts/NunitoSans_10pt-Regular.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-Medium';
    src: url('./../../public/fonts/NunitoSans_10pt-Medium.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-Bold';
    src: url('/public/fonts/NunitoSans_10pt-Bold.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-ExtraBold';
    src: url('/public/fonts/NunitoSans_10pt-ExtraBold.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-Black';
    src: url('/public/fonts/NunitoSans_10pt-Black.ttf');
}

@font-face {
    font-family: 'NunitoSans_10pt-SemiBold';
    src: url('/public/fonts/NunitoSans_10pt-SemiBold.ttf');
}


/* Inter */

@font-face {
    font-family: 'Inter_18pt-Regular';
    src: url('./../../public/fonts/Inter_18pt-Regular.ttf');
}

@font-face {
    font-family: 'Inter_18pt-Medium';
    src: url('./../../public/fonts/Inter_18pt-Medium.ttf');
}

@font-face {
    font-family: 'Inter_18pt-Bold';
    src: url('/public/fonts/Inter_18pt-Bold.ttf');
}

@font-face {
    font-family: 'Inter_18pt-ExtraBold';
    src: url('/public/fonts/Inter_18pt-ExtraBold.ttf');
}

@font-face {
    font-family: 'Inter_18pt-Black';
    src: url('/public/fonts/Inter_18pt-Black.ttf');
}

@font-face {
    font-family: 'Inter_18pt-SemiBold';
    src: url('/public/fonts/Inter_18pt-SemiBold.ttf');
}

/* body {
    font-family: 'CustomFont', sans-serif;
} */


.Source_Regular {
    font-family: 'Source_Regular';
}

.Source_Medium {
    font-family: 'Source_Medium';
}

.Source_Bold {
    font-family: 'Source_Bold';
}

.Source_SemiBold {
    font-family: 'Source_SemiBold';
}

/* Rubik */
.Rubik-Light {
    font-family: 'Rubik-Light';
}

.Rubik-Regular {
    font-family: 'Rubik-Regular';
}

.Rubik-Medium {
    font-family: 'Rubik-Medium';
}

.Rubik-Bold {
    font-family: 'Rubik-Bold';
}

.Rubik-SemiBold {
    font-family: 'Rubik-SemiBold';
}

/* Nunito Sans */

.NunitoSans-Light {
    font-family: 'NunitoSans_10pt-Light';
}

.NunitoSans-Regular {
    font-family: 'NunitoSans_10pt-Regular';
}

.NunitoSans-Medium {
    font-family: 'NunitoSans_10pt-Medium';
}

.NunitoSans-Bold {
    font-family: 'NunitoSans_10pt-Bold';
}

.NunitoSans-ExtraBold {
    font-family: 'NunitoSans_10pt-ExtraBold';
}

.NunitoSans-Black {
    font-family: 'NunitoSans_10pt-Black';
}

.NunitoSans-SemiBold {
    font-family: 'NunitoSans_10pt-SemiBold';
}

/* Inter */

.Inter-Light {
    font-family: 'Inter_18pt-Light';
}

.Inter-Regular {
    font-family: 'Inter_18pt-Regular';
}

.Inter-Medium {
    font-family: 'Inter_18pt-Medium';
}

.Inter-Bold {
    font-family: 'Inter_18pt-Bold';
}

.Inter-ExtraBold {
    font-family: 'Inter_18pt-ExtraBold';
}

.Inter-Black {
    font-family: 'Inter_18pt-Black';
}

.Inter-SemiBold {
    font-family: 'Inter_18pt-SemiBold';
}