.section_padding {
    padding: 1rem 2rem;
}
 
.footer {
    background-color: #f6f7fb;
    /* margin-top: 10px; */
}

.sb_footer {
    display: flex;
    flex-direction: column;
}

.sb_footer_links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.sb_footer_links_div:first-child {
    width: 430px !important;
}
.sb_footer_links_div {
    flex-direction: column;
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    color: #fff;
}

.sb_footer_links_div a{
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    margin-bottom: 3px;
}

.sb_footer_links_div a i{
    font-size: 4px;
    line-height: 20px;
    margin-right: 5px;
}

.sb_footer_links_div h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb_footer_links_div p {
    font-size: 12px;
    line-height: 22px;
    margin-left: 0.5rem 0;
    cursor: pointer;

}


.sb_footer_links_div p i{
    margin-right: 6px;
}

.sb_footer_below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb_footer_below_links{
    display: flex;
    gap: 10px;
    flex-direction: row;
}

.sb_footer_below_links a{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
/* .sb_footer_below_links a:hover{
    color: #a02d03;
} */

hr {
    color: #fff !important;
    width: 100%;
}

.sb_footer_copyright p{
    font-size: 13px;
    /* line-height: 15px; */
    color: #fff;
    font-weight: 600;
}


@media screen and (max-width:850px) {
    .sb_footer_heading h1{
        font-size: 44px;
        line-height: 50px;
    }

}

@media screen and (max-width:574px) {
    .sb_footer_heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .sb_footer_links_div{
        margin: 1rem 0;
        /* background-color: #22c55e; */
    }
    .sb_footer_btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb_footer_below{
        flex-direction: column;
    }
    .sb_footer_below_links{
        margin-top: 10px;
        flex-direction: column;
    }
}

@media screen and (max-width:400px) {
    .section_padding {
        padding: 1rem 0.8rem !important;
    }
    .sb_footer_heading h1{
        font-size: 27px;
        line-height: 38px;
    }
    .sb_footer_links_div{
        width: 140px;
    }
}