/* BottomNavigation.css */
.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid #ddd;
    padding: 6px 0;
    z-index: 78;
    display: none;
}

.bottom-nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 0px 10px;
    text-align: center;
    position: relative;
}

.bottom-nav-link .icon {
    font-size: 20px;
}

.bottom-nav-link .label {
    font-size: 12px;
}

.bottom-nav-link:hover {
    color: #089dca;
}

.bottom-nav-link .active span {
    color: #089dca;
}


.label {
    font-size: 12px;
    /* display: none; */
    transition: opacity 0.3s ease-in-out, display 0s linear 0.3s;
    /* display delay after opacity */
}

.label.show {
    color: #089dca;
}

.menu-tabs {
    padding: 8px 10px;
    width: 100%;
    border-radius: 6px;
}

.menu-tabs.active {
    color: #fff;
    background-color: #5cbcdb;
}

.menu-tabs.disable {
    color: #212 !important;
}

.menu-tabs-auth {
    background: #75b8ff2a !important;
    color: #00599f !important;
    padding: 8px 10px;
    color: #212;
    width: 90%;
    border-radius: 6px;
}

.menu-tabs-logout {
    background: #ff8a752a !important;
    color: #9f2500 !important;
    padding: 8px 10px;
    color: #212;
    width: 100%;
    border-radius: 6px;
}

.menu-page-parent {
    background-color: #000 !important;
    position: absolute;
    width: 80% !important;
    z-index: 9828987 !important;
}

/* .label.hide {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; 
} */

.offcanvas-parent-auth {
    width: 85% !important;
    z-index: 88000 !important;
}


@media (max-width: 768px) {
    .bottom-nav {
        display: flex;
    }

    body {
        padding-bottom: 74px;
    }
}

@media (min-width: 630px) and (max-width: 768px) {
    .offcanvas-parent-auth {
        width: 60% !important;
    }
}



/* Carousel */
.owl-carousel-item {
    height: 420px;
    background-color: #bed4e1 !important;
}

.owl-carousel-item img {
    width: 100%;
    height: 100%;
}


/* Search All */
.gradient-search {
    background: #60efff;
    background: linear-gradient(to right bottom, #1c3e35, #99f2d1);
}

.image-container {
    width: 250px;
    /* Fixed width */
    height: 180px;
    /* Fixed height */
    overflow: hidden;
    /* Prevent overflow */
}

.text-container {
    max-height: 40px;
    /* Adjust as needed */
    overflow: hidden;
    /* Prevent overflow */
    text-overflow: ellipsis;
    /* Show "..." for overflow */
    white-space: nowrap;
    /* Prevent wrapping */
}



.item-fixed-width {
    display: inline-block;
    width: auto;
    max-width: fit-content;
}


/* MSG Animation */
@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.msg-noti-active {
    height: 60px !important;
    animation: slideIn 0.5s forwards;
}

.msg-noti-hidden {
    animation: slideOut 0.5s forwards;
}


/* Navbar */

.custom-nav {
    padding: 0 3% !important;
}

.navbar-light .navbar-nav .nav-link {
    margin-right: 15px;
    color: #FFFFFF;
    font-size: 15px;
    outline: none;
}

.navbar-light .navbar-nav .login-nav-link {
    padding-top: 5px !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: #089dca;
}

.navbar-light .navbar-nav .nav-link {
    color: #333333;
    font-weight: 500;
}

.nav-item {
    color: #27719c;
}


.navbar {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    transition: top 0.3s ease;
}

/* Sticky navbar styles */
.sticky {
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.offcanvas-parent {
    width: 100% !important;
    /* height: 80%; */
    z-index: 88900 !important;
}

.offcanvas-custom {
    box-shadow: none !important;
}

@media (max-width:550px) {
    .custom-nav {
        padding: 0 !important;
    }
}




/* Packages */
.ftco-section {
    padding: 2em 0;
    position: relative;
    width: 100%;
}


.project-destination .img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    /* background-size: contain !important; */
    z-index: 4000 !important;
    overflow: hidden !important;
    transition: transform 0.3s ease-in-out;
}

.project-destination .img:hover {
    transform: scale(1.06);
}

.project-destination .text {
    padding: 20px;
    height: 320px;
}



/* packages */
.package {
    max-height: 320px;
}

.spacing {
    line-height: 20px;
}

.scale-1-1 {
    /* height: 200px !important; */
    transition: transform 0.3s ease-in-out;
}

.scale-1-1:hover {
    transform: scale(1.06) !important;
}

/* .scale-increase-hw-05{
    height: 120px !important;
    transition: height 0.3s ease-in-out;
} */

/* .scale-increase-hw-05{
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
} */

.project-destination.pkg .img {
    height: 250px !important;
}

/* Vehicles */
.project-destination.states {
    width: 100%;
    height: 110px;
}

/* .hoverthis:hover .img {
    transform: scale(1.06);
} */



/* hover */

.hover-shadow {
    position: relative;
    transition: all 0.3s ease;
}

.hover-shadow:hover {
    background-color: aliceblue !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transform: translateY(-10px);
}

.hover-highlight-pkg {
    position: relative;
    transition: all 0.3s ease;
}

.hover-highlight-pkg:hover {
    /* background-color: aliceblue !important; */
    /* box-shadow: 0 2px 3px rgba(0, 108, 196, 0.2); */
    transform: translateY(-5px);
}

/* Month Select */
.month-scroll {
    overflow-x: auto;
    white-space: nowrap;
}

.month-scroll::-webkit-scrollbar {
    height: 4px !important;
}

.month-scroll::-webkit-scrollbar-track {
    background: transparent !important;
}

.month-scroll::-webkit-slider-thumb {
    background-color: #F66F4D !important;
    border-radius: 10px;
}


/* Query Form */
.form-control:focus {
    box-shadow: none;
}

.form-control {
    position: relative !important;
}

/* bg shape */
.bg_shape {
    position: absolute;
    top: 80px;
    right: 0;
}

.bg_shape_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
}


/* nn */
.fixed-image {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 50%;
}


/* Query part */
.query-right-img {
    /* height: auto; */
}

@media (max-width:769px) {
    .query-right-img {
        display: none !important;
    }
}

/* show hide */

.show-on-small {
    display: none;
}

/* .show-on-medium {
    display: none;
} */

.show-on-large {
    display: inline;
}


/* ripple */
.ripple {
    position: relative;
    overflow: hidden;
}

.ripple::after {
    content: '';
    position: absolute;
    background-color: rgb(235, 13, 13);
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 0.5s linear;
}

@keyframes ripple {
    to {
        transform: scale(2);
        opacity: 0;
    }
}


/* Ripple Effect */
.ripple-container {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    transform: scale(0);
    animation: ripple 0.6s linear;
}


@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

/* Expand & Collapse */

.content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
}

.content.expanded {
    max-height: 390px;
    transition: max-height 0.5s ease-in;
}

.content.collapsed {
    max-height: 0;
    transition: max-height 0.5s ease-out;
}

.box-shadow {
    box-shadow: 0px 0px 5px 0px #d5d5d5;
}



/*  */

.toggle-switch {
    background-color: #b00e0e;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    width: 90%;
}

.toggle-switch-inner {
    /* width: 28px;
    height: 28px; */
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1.2px;
    left: 2.8px;
    transition: transform 0.3s;
}

.toggle-switch.checked {
    transition: background-color 0.9s ease-in-out;
    background-color: blue;

}

.toggle-switch.checked .toggle-switch-inner {
    transform: translateX(15px);
}

.ant-modal-body {
    overflow-y: hidden;
    height: 85vh;
}

.ant-modal-close {
    background-color: rgba(255, 255, 255, 0.9) !important;
    border-radius: 6px !important;
    padding: 4px !important;
    color: rgb(65, 65, 65) !important;
}


.d-show-on-mdl {
    display: none;
}

/* flip */
.rotate-img-flip {
    transform: scaleX(-1);
}

/* input */
/* Hide the arrows in Chrome, Safari, Edge */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the arrows in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.d-show-on-xs {
    display: none !important;
}

/* xxLarge */
@media (min-width: 1285px) {
    .justify-center-xxl {
        justify-content: center !important;
    }
}

/* Large */
@media (max-width: 990px) {
    .d-show-on-mdl {
        display: block;
    }

    .d-none-on-lg {
        display: none !important;
    }

    .px-xl-4 {
        padding: '0 24px' !important;
    }

    .h-lg-380px {
        height: 380px !important;
    }

    .w-100-lg {
        width: 100% !important;
    }
}

.d-show-on-md {
    display: none;
}

/* MediaMedium */
@media (max-width: 769px) {
    .row-remove {
        flex-wrap: nowrap !important;
    }

    .justify-content-initial {
        justify-content: initial !important;
    }

    .show-on-small {
        display: inline !important;
    }

    .show-on-large {
        display: none;
    }

    .d-none-on-md {
        display: none;
    }

    .d-show-on-md {
        display: block;
    }

    .w-md-200px {
        width: 200px !important;
    }

    .w-49-md {
        width: 49% !important;
    }

    .w-100-md {
        width: 100% !important;
    }

    .h-md-200px {
        height: 200px !important;
    }

    .h-250px-sm {
        height: 250px !important;
    }

    .h-350px-md {
        height: 350px !important;
    }

    .h-auto-md {
        height: auto !important;
    }

    .mb-md-3 {
        margin-bottom: 16px !important;
    }

    .me-3-md {
        margin-right: 16px !important;
    }

    .me-2-md {
        margin-right: 10px !important;
    }

    .mb-md-4 {
        margin-bottom: 24px !important;
    }

    .mt-4-md {
        margin-top: 24px !important;
    }

    .container-full-xss {
        width: 100%;
        max-width: 100%;
    }

    .d-block-md {
        display: block !important;
    }

}

/* MediaSmall */

@media (max-width: 575.5px) {
    .d-none-on-xs {
        display: none !important;
    }

    .d-show-on-xs {
        display: block !important;
    }

    .w-xs-auto {
        width: auto !important;
    }

    .w-100-xs {
        width: 100% !important;
    }

    .ps-20px {
        padding-left: 20px !important;
    }

    .mx-xs-4 {
        margin: 0 24px !important;
    }

    .me-0-xs {
        margin-right: 0px !important;
    }

    .fs-sm-22 {
        font-size: 22px !important;
    }

    .mb-xs1-20 {
        margin-bottom: 20px !important;
    }

    .mt-sm-10 {
        margin-top: 10px !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .h-xs-250px {
        height: 250px !important;
    }

    .h-xs-300px {
        height: 330px !important;
    }

    .hw_55px-sm {
        height: 55px !important;
        width: 55px !important;
    }

    .hw_30px-sm {
        height: 30px !important;
        width: 30px !important;
    }

    .justify-sm-center {
        justify-content: center;
    }

    .flex-column-sm {
        flex-direction: column !important;
    }
}

/* MediaXSmall */

@media (max-width:499px) {

    .toggle-switch {
        width: 60px !important;
        height: 30px;
    }

    .fs-xs-14 {
        font-size: 14px !important;
    }

    .fs-xs-16 {
        font-size: 16px !important;
    }

    .fs-xs-18 {
        font-size: 18px !important;
    }

    .fs-xs-32 {
        font-size: 32px !important;
    }

    .px-xs-0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mr-xs-15 {
        margin-right: 15px !important;
    }

    .mb-xs-10 {
        margin-bottom: 10px !important;
    }

    .mb-xs-20 {
        margin-bottom: 20px !important;
    }

    .ml-0px-xs {
        margin-left: 0px !important;
    }

    .w-xxs-90 {
        width: 90% !important;
    }

    .w-xxs-99 {
        width: 99% !important;
    }

    .h-xxs-70 {
        height: 70% !important;
    }

    .h-xxs-35px {
        height: 35px !important;
    }

    .h-xxs-150px {
        height: 150px !important;
    }

    .h-xxs-200px {
        height: 200px !important;
    }

    .ant-modal-body {
        height: auto;
    }

    .m-xxs-auto {
        margin: auto !important;
    }

    .flex-column-xs {
        flex-direction: column !important;
    }
}

@media (max-width:429px) {
    .flex-column-xs {
        flex-direction: column !important;
    }
}


.scrollable-container {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.white-space {
    white-space: nowrap;
}

.scrollable-container::-webkit-scrollbar {
    height: 2px;
}

/* if needed */
.scrollable-container.w-5::-webkit-scrollbar {
    width: 5px;
}

.scrollable-container::-webkit-scrollbar-track {
    /* background: #f1f1f1; */
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #a8ebff;
    border-radius: 1px;
}

/* Custom Dots */
.custom-dot {
    width: 22px;
    height: 4.5px;
    border-radius: 0;
    background: #b2b2b2;
    margin: 0 5px;
}

/*Custom Scrollbar Select Dropdown CSS*/
.custom-select__menu-list::-webkit-scrollbar {
    width: 5px;
}

.custom-select__menu-list::-webkit-scrollbar-thumb {
    background: #fedac7;
    border-radius: 2px;
}

/* Custom scrollbar for AntModal */
.custom-modal .ant-modal-body::-webkit-scrollbar {
    width: 5px;
}

.custom-modal .ant-modal-body::-webkit-scrollbar-thumb {
    background: #fedac7;
    border-radius: 2px;
}




/* collapse */
/* App.css */

.collapse-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.1s ease-in-out, ;
}

.collapse-content.expanded {
    max-height: 500px;
}


/* Counter */

.counter-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.counter-controls button {
    background-color: #007bff;
}

.counter-controls span {
    margin: 0 10px;
    font-size: 18px;
}

/*  */
.view-all-text {
    bottom: 10px;
    /* right: 10px; */
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
}

.ant-modal-content {
    /* height: 80vh !important; */
    padding: 3px !important;
    /* overflow: hidden !important; */
}


/* Booking tabs */

.nav-item-booking {
    background-color: white;
}

.nav-item-booking .nav-link-booking:hover {
    background: #2e2e3a !important;
    color: white !important;
}

.nav-link-booking:hover span {
    color: white !important;
}

.nav-link-booking.active {
    background: #2e2e3a !important;
    color: white !important;
}

/* Home Banner */
.home_service_img img {
    width: 95%;
}

.inline-block {
    display: unset !important;
}


/* Query */

#query {
    background-color: #E1F2F6;
    height: 190px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

#query img {
    height: 210px !important;
    top: -30px !important;
    position: relative;
}

.center-me {
    position: absolute !important;
    top: 0px;
    /* left: 10px; */
}

.center-me h5 {
    transform: rotate(-20deg);
    color: #ffa5a5;
}


/* dropdwon */
.custom-select-container {
    position: relative;
}

.custom-select-wrapper {
    position: relative;
    width: 100%;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: white;
    z-index: 1000;
}

.dropdown div:hover {
    background-color: #f0f0f0;
    color: #000 !important;
}


/* Invoice.css */

.receipt-main {
    background: #ffffff;
    border-bottom: 12px solid #333333;
    border-top: 12px solid #535361;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0px 20px !important;
    box-shadow: 0 1px 10px #c0cde5;
    color: #333333;
    font-family: 'Open Sans', sans-serif;
}

.receipt-main p {
    color: #333333;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.42857;
}

.receipt-footer h1 {
    font-size: 15px;
    font-weight: 400 !important;
    margin: 0 !important;
}

.receipt-main::after {
    background: #282835;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: -13px;
}

.receipt-main thead th {
    background: #535361 !important;
}

.receipt-main thead th {
    color: #fff;
}

.receipt-right h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 7px 0;
}

.receipt-right p {
    font-size: 12px;
    margin: 0;
}

.receipt-right p i {
    text-align: center;
    width: 18px;
}

.receipt-main td {
    padding: 9px 20px !important;
}

.receipt-main th {
    padding: 13px 20px !important;
}

.receipt-main td {
    font-size: 13px;
    font-weight: initial !important;
}

.receipt-main td p:last-child {
    margin: 0;
    padding: 0;
}

.receipt-main td h2 {
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
}

.receipt-header-mid .receipt-left h1 {
    font-weight: 100;
    margin: 34px 0 0;
    text-align: right;
    text-transform: uppercase;
}

.receipt-header-mid {
    margin: 24px 0;
    overflow: hidden;
}

#container {
    background-color: #dcdcdc;
}

/*  */

:root {
    --bg-clr: #00599f;
    --white: #fff;
    --text-clr: #706f6f;
    --success-clr: #3cb878;
    --success-hvr: #059249;
    --error-clr: #fd5664;
    --error-hvr: #ff0016;
    --cancel-clr: #d4d9e5;
}


.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.modal_btns {
    display: flex;
    justify-content: space-between;
    width: 350px;
    height: 100px;
}

.button_modal {
    display: block;
    width: 160px;
    border: 2px solid transparent;
    padding: 12px 15px;
    border-radius: 3px;
    color: var(--white);
    cursor: pointer;
    transition: background 0.5s ease;
}

.modal_btns .button_modal.success_btn {
    margin-right: 10px;
}

.button_modal.success_btn {
    background: var(--success-clr);
}

.button_modal.error_btn {
    background: var(--error-clr);
}

.button_modal.success_btn:hover {
    background: var(--success-hvr);
}

.button_modal.error_btn:hover {
    background: var(--error-hvr);
}

.modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 999;
}

.modal_wrapper.active {
    visibility: visible;
}

.modal_wrapper .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.modal_wrapper.active .shadow {
    opacity: 1;
}

.modal_wrapper .modal {
    width: 350px;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    padding: 10px;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
}

.modal_wrapper .modal .modal_item {
    width: 100%;
    background: var(--white);
    border-radius: 3px;
    /* position: relative; */
}

.modal_item.active {
    display: block;
}

.modal_item .modal_body {
    padding: 35px;
    text-align: center;
    /* position: relative; */
}

.modal_item .modal_body .s_icon {
    width: 60px;
    height: 60px;
    background: var(--success-clr);
    color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    margin: 0 auto 15px;
}

.modal_item.e_modal .modal_body .s_icon {
    background: var(--error-clr);
}

.modal_item.s_modal .modal_body .s_text h2 {
    color: var(--success-clr);
}

.modal_item.e_modal .modal_body .s_text h2 {
    color: var(--error-clr);
}

.modal_item .modal_body .s_text p {
    margin-top: 5px;
}

.modal .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    background: var(--white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    z-index: 99999999999998;
    transition: background 0.5s ease;
}

.modal_item.s_modal .close:hover {
    background: var(--success-clr);
    color: var(--white);
}

.modal_item.e_modal .close:hover {
    background: var(--error-clr);
    color: var(--white);
}

.modal_item .s_button button {
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

/* tooltip */
.tooltip {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    background-color: #f8d7da;
    color: #721c24 !important;
    padding: 5px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
    z-index: 1000;
}

/* Package Listing*/
.book-now {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pkg-btn-hover:hover .book-now {
    background-color: #089dca;
    color: #fff !important;
}


/* MessagingPopup.css */

.msg-noti {
    position: fixed;
    top: 0;
    right: 20px;
    z-index: 9999;
    height: 60px !important;
}

.messaging-popup {
    position: fixed;
    bottom: 0px;
    right: 20px;
    z-index: 9999;
}

@media (max-width: 768px) {
    .messaging-popup {
        bottom: 50px;
        right: 15px;
    }
}


.messaging-popup .float-chat-button {
    position: relative;
    right: 0px !important;
    /* width: 100%; */
}

.messaging-popup .chat-list {
    height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    /* border */
}

.chat-list::-webkit-scrollbar {
    width: 3px;
}

.chat-list::-webkit-scrollbar-thumb {
    background: #fedac7;
    border-radius: 1px;
}

.chat-list::-webkit-scrollbar {
    background: #00599f !important;
    width: 10px;
}

.messaging-popup .messages-list {
    display: flex;
    flex-direction: column;
    height: 70vh;
    background-color: #000 !important;
}


.messaging-popup .header {
    position: sticky;
    top: 0px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3;
}

.messaging-popup .messages {
    flex: 1;
    overflow-y: auto;
}

.messaging-popup .messages.me {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.messaging-popup .message {
    max-width: 80%;
}

.messaging-popup .message.me {
    background: linear-gradient(to right bottom, #ffbc74, #ff9729);
    color: white;
}

.messaging-popup .message.other {
    background-color: #f1f1f1;
}

.messaging-popup .chats-body {
    /* flex-grow: 1px; */
    flex: 1;
    overflow-y: auto;
}


.chats-body::-webkit-scrollbar {
    width: 3px;
}

.scrollable-width {
    overflow-y: auto;
    scrollbar-color: #e1e1e1 transparent;
}


.messaging-popup .footer {
    /* position: sticky;
    bottom: 0px; */
    border-top: 1px solid #ddd;
    padding: 10px;
    background-color: #f8f9fa;
}

.messaging-popup .list-group-item {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #f1f1f1;
}

.messaging-popup .list-group-item:last-child {
    border-bottom: none;
}

.messaging-popup .list-group-item img {
    margin-right: 10px;
}

/* sticky header */
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fcfcfc;
    width: 100%;
    padding-top: 20px !important;
}


/* iframe youtube */
/* .content-wrapper {
    width: 100%;
    overflow: hidden;
}

.content-wrapper iframe {
    max-width: 100%;
    height: auto;
} */

.content-wrapper {
    font-family: 'Source Sans Pro', sans-serif;
}

.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    /* Limiting to 10 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.expanded {
    display: block;
}

/* Make sure the input field spans full width */
.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100% !important;
    box-sizing: border-box;
}

.blur {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.0.5); */
    backdrop-filter: blur(2.5px);
    border-radius: 2px;
}


/* antd */
/* .custom-select .ant-select:focus,
.custom-select .ant-select:focus-visible,
.custom-select .ant-select:hover {
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none;
}

.custom-select .ant-select-selector {
    border: none;
} */

/* Customizing checkbox checked color */
/* Prevent hover styles from overriding the checked color */
/* Customizing checked state */
/* Customizing the checkbox when checked using your provided class */
.css-dev-only-do-not-override-1x0dypw.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #089dca;
    /* Custom checked background */
    border-color: #089dca;
    /* Custom checked border */
}

/* Customizing the check mark color when checked */
.css-dev-only-do-not-override-1x0dypw.ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: white;
    /* Custom check mark color */
}

/* Ensuring hover doesn't override checked color */
.css-dev-only-do-not-override-1x0dypw.ant-checkbox-checked:hover .ant-checkbox-inner {
    background-color: #089dca;
    /* Keep checked color on hover */
    border-color: #089dca;
    /* Keep checked border color on hover */
}

.css-dev-only-do-not-override-1x0dypw.ant-checkbox-checked:hover .ant-checkbox-inner:after {
    border-color: white;
    /* Keep check mark white */
}





.thm-spin .ant-spin-dot {
    color: #089dca !important;
    /* Use !important if necessary */
}



/* rating summary */

.progress-bar {
    background-color: #eee;
    height: 8px;
    margin: 0 10px;
}

.review-count {
    width: 50px;
    text-align: right;
}


/* youtube video */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.custom-select__menu::-webkit-scrollbar {
    width: 8px;
}

.custom-select__menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.custom-select__menu::-webkit-scrollbar-track {
    background: transparent;
}


/* custom-dropdown */

.custom-dropdown {
    width: 120px;
    height: 130px;
    margin-right: -10px;
}

.ant-dropdown .ant-dropdown-arrow {
    margin-right: -20px !important;
    margin-top: 0.4px !important;
    /* background-position: cove; */
}


/* App.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure modal is on top */
}

.modalContent {
    position: relative;
    background: #fff;
    width: 80%;
    /* Set the width of the modal */
    max-width: 600px;
    /* Maximum width */
    height: 80%;
    /* Set the height of the modal */
    max-height: 400px;
    /* Maximum height */
    border-radius: 8px;
    /* Optional: rounded corners */
    overflow: hidden;
    /* Hide overflow */
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}



/* Accordion */
.accordion-button:not(.collapsed) {
    background-color: #089dca;
    color: #fff;
    height: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: none !important;
}


.accordion-button:not(.collapsed)::after {
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: none;
}

.accordion-button:focus {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px !important;
    box-shadow: none !important;
}

/* Change arrow color to white */
.accordion-button::after {
    background-image: none;
}

/* Custom background for accordion items */
.accordion-item-custom {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-color: transparent !important;
    border: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
}

.accordion-button {
    font-size: 14px;
    height: 50px !important;
    border-top: 1px solid #d5d5d5;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Hide the Google Translate toolbar */
/* .translated-ltr {
    display: none !important;
  } */