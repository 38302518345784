.main_container {
  font-family: 'Karla', sans-serif;
  background-image: url("https://img.freepik.com/free-photo/beautiful-girl-standing-viewpoint-koh-nangyuan-island-near-koh-tao-island-surat-thani-thailand_335224-1094.jpg?t=st=1729861709~exp=1729865309~hmac=bccb6a6bade96b9df8ed8c02f0fa7332d2a1cc3d87fd7bb16b0947b179e32755&w=1380");
  /* background-image: url("  https://img.freepik.com/premium-photo/coming-soon-announcement-cover-banner_749638-12311.jpg?ga=GA1.1.2031314853.1709622349&semt=ais_hybrid");
  background-image: url("https://img.freepik.com/premium-photo/coming-soon-announcement-cover-banner_749638-12339.jpg?ga=GA1.1.2031314853.1709622349&semt=ais_hybrid"); */
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-position: center;
  color: #ffffff;
}


@media (min-width: 768px) {
  .main_container {
    padding-bottom: 110px;
  }
}

.page-title {
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 6px;
  text-shadow: #000;
  
}

@media (max-width: 767px) {
  .page-title {
    font-size: 35px;
    margin-bottom: 14px;
  }
}

.page-description {
  font-size: 18px;
  margin-bottom: 59px;
}

@media (max-width: 767px) {
  .page-description {
    font-size: 14px;
  }
}

p {
  font-size: 14px;
  margin-bottom: 21px;
}